var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-breadcrumb",
    { staticClass: "mb-0" },
    [
      _vm.isRootFolder
        ? _c("b-breadcrumb-item", { attrs: { active: "" } }, [
            _vm.isGroupUser === true ||
            _vm.accessToRootFolderSettings ||
            _vm.teamAccessToRootFolder
              ? _c("h2", { staticClass: "mb-0" }, [
                  _vm._v(" " + _vm._s(_vm.$t("webmag.dashboard")) + " ")
                ])
              : _c("h2", { staticClass: "mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("webmag.dashboard-teams")))
                ])
          ])
        : _c(
            "div",
            { staticClass: "folder-project-breadcrumb-wrapper" },
            [
              _c(
                "b-breadcrumb-item",
                { staticClass: "mt-1", attrs: { to: "/" } },
                [
                  _c("b-icon", {
                    attrs: {
                      icon: "house-door-fill",
                      scale: "1.25",
                      "shift-v": "1.25",
                      "aria-hidden": "true"
                    }
                  })
                ],
                1
              ),
              _vm._l(_vm.itemsInBreadcrumb, function(item) {
                return _c(
                  "b-breadcrumb-item",
                  {
                    key: item.to,
                    attrs: {
                      to: item.to,
                      disabled: item.disabled,
                      active: item.active
                    }
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass: "mb-0",
                        attrs: { id: `tooltip-button-variant-${item.to}` }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("sliceStingPos30")(item.text)) + " "
                        )
                      ]
                    ),
                    item.text.length > 30
                      ? _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: `tooltip-button-variant-${item.to}`
                            }
                          },
                          [_vm._v(" " + _vm._s(item.text) + " ")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          ),
      _vm.accessToRootFolderSettings || _vm.accessToFolderSettings
        ? _c(
            "router-link",
            {
              staticClass:
                "settings-item folder-settings-link mr-auto mt-1 ml-2",
              attrs: {
                "data-dashboard-step": "2",
                to: `${
                  _vm.$route.path === "/" ? "" : _vm.$route.path
                }/folder-settings`
              }
            },
            [
              _c("b-icon", {
                attrs: { icon: "gear-fill", "aria-hidden": "true" }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.accessToProjectSettings
        ? _c(
            "router-link",
            {
              staticClass: "settings-item project-settings-link mt-1 ml-2",
              attrs: {
                "data-dashboard-step": "2",
                to: `${_vm.$route.path}/project-settings`
              }
            },
            [
              _c("b-icon", {
                attrs: { icon: "gear-fill", "aria-hidden": "true" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }