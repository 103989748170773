<template>
  <b-breadcrumb class="mb-0">
    <b-breadcrumb-item
      v-if="isRootFolder"
      active
    >
      <h2
        v-if="isGroupUser === true || accessToRootFolderSettings || teamAccessToRootFolder"
        class="mb-0"
      >
        {{ $t('webmag.dashboard') }}
      </h2>
      <h2 v-else class="mb-0">{{ $t('webmag.dashboard-teams') }}</h2>
    </b-breadcrumb-item>
    <div class="folder-project-breadcrumb-wrapper" v-else>
      <b-breadcrumb-item class="mt-1" to="/">
        <b-icon icon="house-door-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item
        v-for="item in itemsInBreadcrumb"
        :key="item.to"
        :to="item.to"
        :disabled="item.disabled"
        :active="item.active">
        <h2
          :id="`tooltip-button-variant-${item.to}`"
          class="mb-0">{{ item.text | sliceStingPos30 }}
        </h2>
        <b-tooltip
          v-if="item.text.length > 30"
          :target="`tooltip-button-variant-${item.to}`"
        >
          {{ item.text }}
        </b-tooltip>
      </b-breadcrumb-item>
    </div>
    <router-link
      data-dashboard-step="2"
      v-if="accessToRootFolderSettings || accessToFolderSettings"
      class="settings-item folder-settings-link mr-auto mt-1 ml-2"
      :to="`${($route.path === '/') ? '' : $route.path}/folder-settings`">
      <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
    </router-link>
    <router-link
      data-dashboard-step="2"
      v-if="accessToProjectSettings"
      class="settings-item project-settings-link mt-1 ml-2"
      :to="`${$route.path}/project-settings`">
      <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
    </router-link>
  </b-breadcrumb>
</template>

<script>
export default {
  name: 'FolderAndProjectBreadcrumb',
  props: {
    itemsInBreadcrumb: { type: Array },
    currentPageType: { type: String },
    isGroupUser: { type: Boolean, default: null },
    teamAccessToRootFolder: { type: Boolean, default: false },
    accessToRootFolderSettings: { type: Boolean, default: false },
    accessToFolderSettings: { type: Boolean, default: false },
    accessToProjectSettings: { type: Boolean, default: false },
  },
  computed: {
    isRootFolder() {
      return !this.$route.params.parametersOfUrl;
    },
  },
  filters: {
    sliceStingPos30(value) {
      return (value.length > 30) ? `${value.slice(0, 30)}...` : value;
    },
  },
};
</script>

<style scoped lang="scss">
.folder-project-breadcrumb-wrapper {
  display: flex;
  flex-wrap: wrap;
}

/deep/ a.disabled:hover {
  color: #777C7F !important;
  cursor: not-allowed;
}
</style>
